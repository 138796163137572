:root
  --plyr-color-main: theme(colors.green.500)
  --plyr-video-control-color: #fff
  --plyr-audio-control-color: #fff
  --plyr-badge-text-color: #fff
  --plyr-menu-color: theme(colors.green.500)
  --plyr-menu-background: #fff


.video-player

  &.video-player-hide-controls
    .plyr.plyr--stopped
      .plyr__controls
        display: none
      .plyr__control
        display: none

  button.plyr__control
    padding: 15px

  .plyr__control .plyr__controls__item
    padding: 7px

  .plyr__controls .plyr__controls__item
    color: #fff

  .plyr__time
    color: #fff

.video-player-rounded
  .plyr
    @apply rounded-2xl

.plyr--video .plyr__controls
  button
    padding: 7px
